

html{
    box-sizing: border-box;
}

body.rvModal{
    overflow-y:hidden;

}

#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey
}

#progressbar .active {
    color: #000000
}

#progressbar li {
    list-style-type: none;
    font-size: 12px;
    width: 25%;
    float: left;
    position: relative
}

#progressbar #account:before {
    font-family: FontAwesome;
    content: "\f023"
}


#progressbar #account:before {
    font-family: FontAwesome;
    content: "\f023"
}

#progressbar #check:before {
    font-family: FontAwesome;
    content: "\f00c"
}


#progressbar #personal:before {
    font-family: FontAwesome;
    content: "\f007"
}

#progressbar #payment:before {
    font-family: FontAwesome;
    content: "\f09d"
}

#progressbar #confirm:before {
    font-family: FontAwesome;
    content: "\f00c"
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 18px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: 1;
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: skyblue;
}

/* inner */
#innerprogressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey;
}

#innerprogressbar .active {
    color: #000000;
}

#innerprogressbar li {
    list-style-type: none;
    font-size: 12px;
    width: 33%;
    float: left;
    position: relative;
}



#innerprogressbar #choose:before {
    font-family: FontAwesome;
    content: "\f047"
}

#innerprogressbar #payselect:before {
    font-family: FontAwesome;
    content: "\f09d"
}


#innerprogressbar #paynow:before {
    font-family: FontAwesome;
    content: "\f283"
}



#innerprogressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 18px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px;
}

#innerprogressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: 2;
}

#innerprogressbar li.active:before,
#innerprogressbar li.active:after {
    background: lightgreen
}

.spin-overlay {
    position: fixed;
    z-Index: 3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.3);
}


.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
    margin:-60px 0 0 -60px;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
    color: #fff;
}

.spinner.dark {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
    margin:-60px 0 0 -60px;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
    color: rgba(0,0,0,0.6);
}


.DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_2{
    display:block;
} 
.DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_2:after{
    clear: both;
} 

.DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_2 .rvButtonLeft{
    padding: 20px 20px;
    display:block;
    float: left;

}
.DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_2 .rvButtonRight{
    padding: 20px 20px;
    display:block;
    float: right;

}

.DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_2 button {
    background-color: transparent;
    padding-top: 8px;
    padding-bottom: 5px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 2px;

}



@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


.fade-in {
    animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;
    -moz-animation: fadeIn ease 5s;
    -o-animation: fadeIn ease 5s;
    -ms-animation: fadeIn ease 5s;
    }
    @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }


    .fade-in2s {
        animation: fadeIn ease 2s;
        -webkit-animation: fadeIn ease 2s;
        -moz-animation: fadeIn ease 5s;
        -o-animation: fadeIn ease 2s;
        -ms-animation: fadeIn ease 2s;
        }
        @keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }
        
        @-moz-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }
        
        @-webkit-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }
        
        @-o-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }
        
        @-ms-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
        }


        .DateInput_input{
            font-size: 14px;
        }

        .container-fluid.no-gutters{
            padding-left: 0px;
            padding-right:0px;
        }




        

@media (max-width: 576px){
    .col-sm-12.no-gutters{
        padding-left: 0px;
        padding-right: 0px;
    }
    .DateInput{
        width: 120px;
    }
    .DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__vertical.DayPickerNavigation_button__vertical_2{
        display:block;
    } 
    .DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__vertical.DayPickerNavigation_button__vertical_2:after{
        clear: both;
    } 

    .DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__vertical.DayPickerNavigation_button__vertical_2 .rvButtonLeft{
        padding: 20px 20px;
        display:block;
        float: left;

    }
    .DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__vertical.DayPickerNavigation_button__vertical_2 .rvButtonRight{
        padding: 20px 20px;
        display:block;
        float: right;

    }
   
    .DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__vertical.DayPickerNavigation_button__vertical_2 button {
        background-color: transparent;
        padding-top: 8px;
        padding-bottom: 5px;
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 2px;

    }

}











