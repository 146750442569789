.p {

    &__cartItemName{
        text-transform: none;
    }
    &__cartSubItemName{
        text-transform: none;
        font-size: 1.1em;
    }
    &__actionButton{ 
        color: #3f51b5;
        background-color: transparent;
        & svg{
            width: 2.5em;
            height: 2.5em;
        }
        &:hover, &:active{
            background-color:transparent;
            border: none;
            color: #3c3f57;
        }          
    }
    &__dangerButton{ 
        background-color:transparent;
        color: #d9534f;
        & svg{
            width: 2.5em;
            height: 2.5em;
        }
        &:hover{
            background-color:transparent;
            color: #b44946;
        } 
        &:active{
            background-color:transparent;
            color: #b44946;
        }      
    }
    &__dangerSmallButton{ 
        background-color:transparent;
        text-transform: none;
        font-size: 0.7em;
        font-weight: 200;
        color: #d9534f;
        & svg{
            width: 2em;
            height: 2em;
        }
        &:hover{
            background-color:transparent;
            color: #b44946;
        } 
        &:active{
            background-color:transparent;
            color: #b44946;
        }      
    }    
    &__currency{
        text-transform: none;
    } 
    &__info{
        font-size: 0.8em;
        font-style: italic ;
        padding-bottom: 10px;
    }
    &__payInfo{
        font-size: 0.8em;
        font-style: italic;
    }
    &__modal{
        border: 10px solid rgba(255,255,255,0.2);
        border-radius: 5px;
    }
    &__colTitle{
        font-weight: 800;
        color: #000;
        font-size: 1.2em;
    }   
    &__rVieModalFooterText{
        font-size: 0.7em;
        display: inline-block;
    } 
    &__dateList{
        font-style: italic;
        padding: 1em;
    }
    &__date{
        font-style: italic;
    }
}