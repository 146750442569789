.p {
    &__grid{ 
        padding-bottom: 10px;
    }
    &__outline {
        font-weight: bold;
        color: #000;
        font-size: 1em;
        margin-bottom: 10px;
    }
    &__Innercontainer{
        padding-bottom: 20px;
    }
    &__rVieEventtitle{
        color: #006db3;
        font-size: 1.05em;
        padding-bottom: 0.5em;
    }
    &__rVieInfo{
        font-size: 0.9em;
        color: rgba(0,0,0,0.5);
        padding-bottom: 0.2em;
    }
    &__rVieInfoLnk{
        margin: auto;
        text-align: left;
        font-size: 0.8em;
        padding: 0 0;
        display: block;
        margin-top: 5px;
        margin-bottom: 10px;

    }    
    &__rVieBtnInfo{
        text-align: left;
        font-size: 0.9em;
        font-weight: 500;
        color: rgba(0,0,0,0.9);
        padding-bottom: 0.2em;
    }  
    &__rVieModalBtnInfo{
        text-align: center;
        font-size: 0.8em;
        font-weight: 500;
        color: rgba(0,0,0,0.9);
        padding-bottom: 0.2em;
    }        
    &__rVieBtn{
        min-height: 40px;
        min-width: 180px;
        width: 100%;
        padding: 0.5rem 1rem;
        margin: 3px auto 3px auto;
        font-size: 0.7em;
        background-color: #3f51b5;
        border-color:  #3f51b5;
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: #3f51b5;
            opacity: 0.9;    
            color: #fff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }

    }  
    &__rVieDangerBtn{
        min-height: 40px;
        min-width: 180px;
        width: 100%;
        padding: 0.5rem 1rem;
        margin: 3px auto 3px auto;
        font-size: 0.7em;
        background-color: rgba(0,0,0,0.6);
        border-color: rgba(0,0,0,0.65);
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: rgba(0,0,0,0.4);
            opacity: 0.9;    
            color: #fff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }

    }     
    
    &__rVieBtnOutline{
        min-height: 40px;
        width: 180px;
        padding: 0.5rem 1rem;
        margin: 3px auto 3px auto;
        font-size: 0.7em;
        background-color: #fff;
        border-color:  #3f51b5;
        border-width: 1px;
        color: #3f51b5;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: #fff;
            opacity: 0.9;    
            color: #3f51b5;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }

    } 
    &__rVieMultiBtn{
        min-height: 40px;
        width: 180px;
        padding: 0.5rem 1rem;
        margin: 3px auto 3px auto;
        font-size: 0.7em;
        background-color: #3f51b5;
    }
    &__rVieBtnInfoCenter{
        text-align: center;
    }
    &__rVieLnk{
        margin:auto;
        text-align: center;
    }
    &__rVieBtn:hover{
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    &p__rVieOptionBlock{
        text-align: center;
        margin: auto;
        display: block;
    }
    &__rVieModalFooterText{
        font-size: 0.7em;
        display: block;
    }
    &__rVieStrong{
        color: #000;
        font-weight: bold;

    }
    &__rvFrame{
        border: none;
        width: 100%;
        overflow-y: none;
    }

    &__qtyContainer{
        min-width: 180px;
        width: 100%;
        display: block;
        margin: auto;
        text-align: center;
    }
    &__qtyDigit{
        display: inline-block;
        text-align: center;
    } 

    &__qtyMore{
        background-color: #3f51b5;
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: #3f51b5;
            opacity: 0.9;    
            color: #fff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }
        &:disabled{
            background-color: #858585;
            cursor: not-allowed;
            pointer-events: all !important;            
        }
    }
    

    &__qtyLess{
        background-color: #858cb3;
        border-color:  #858cb3;
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: #6c739b;
            opacity: 0.9;    
            color: #fff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }
        &:disabled{
            background-color: #858585;
            cursor: not-allowed;
            pointer-events: all !important;            
        }        
    }
    &__places{
        font-size: 0.8em;
    }
}


