.p {
    &__grid{ 
        padding-bottom: 10px;
    }  
    &__rowCenter{
        justify-content: center;
    }    
    &__rVieBtn{
        min-height: 40px;
        width: 100%;
        padding: 0.5rem 1rem;
        margin: 3px auto 3px auto;
        font-size: 0.7em;
        background-color: #3f51b5;
        border-color:  #3f51b5;
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: #3f51b5;
            opacity: 0.9;    
            color: #fff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }

    }  
    &__outerButtonContainer{
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
    &__qtyContainer{
        max-width: 100%;
        display: block;
        margin: 0;
        text-align: center;
    }
    &__qtyDigit{
        display: inline-block;
        text-align: center;
    }
    &__qtyMore{
        background-color: #3f51b5;
        border-color:  #3f51b5;
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: #3f51b5;
            opacity: 0.9;    
            color: #fff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }
        &:disabled{
            background-color: #858585;
            cursor: not-allowed;
            pointer-events: all !important;            
        }
    }
    &__qtyLess{
        background-color: #858cb3;
        border-color:  #858cb3;
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: #6c739b;
            opacity: 0.9;    
            color: #fff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }
        &:disabled{
            background-color: #858585;
            cursor: not-allowed;
            pointer-events: all !important;            
        }        
    }
    &__places{
        font-size: 0.8em;
    }
}


