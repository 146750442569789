.p{
    &__container{
        text-align: left;
    }
    &__rvFrame{
        border: none;
        width: 100%;
        overflow-y: none;
    }
    &__wait{
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: rgba(0,0,0,0.8);
    }
    &__title{
        margin-top: 10px;
        margin-bottom: 30px;
    }
    &__signin{
        max-width: 800px;
        width: 100%;
        margin: auto;
    }
    &__form{
        margin-top: 30px;
    }
    &__rVieBtn{
        font-size: 12px;
        min-height: 40px;
        padding: 0.5rem 1rem;
        margin: 30px auto 3px auto;
        background-color: #3f51b5;
        border-color:  #141414;
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: #3f51b5;
            opacity: 0.9;    
            color: #fff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }

    } 
    &__manageLinks{
        margin: 10px 0px 0px 0px;
    }
    &__forgotLink{
        text-align: right;
        font-size: 12px;
    }
    &__registerLink{
        text-align: left;
        font-size: 12px;
    }
    &__show{
        text-align: right;
        font-size: 0.7em;
    } 
    &__cartItemName{
        text-transform: none;
    }
    &__actionButton{ 
        color: #3f51b5;
        background-color: transparent;
        & svg{
            width: 2.5em;
            height: 2.5em;
        }
        &:hover, &:active{
            background-color:transparent;
            border: none;
            color: #3c3f57;
        }          
    }
    &__dangerButton{ 
        background-color:transparent;
        color: #d9534f;
        & svg{
            width: 2.5em;
            height: 2.5em;
        }
        &:hover{
            background-color:transparent;
            color: #b44946;
        } 
        &:active{
            background-color:transparent;
            color: #b44946;
        }      
    }
    &__currency{
        text-transform: none;
    } 
    &__info{
        font-size: 0.8em;
        font-style: italic ;
        padding-bottom: 10px;
    }         
}