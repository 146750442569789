.p{
    &__container{
        text-align: left;
    }
    &__rvFrame{
        border: none;
        width: 100%;
        overflow-y: none;
    }
    &__wait{
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: rgba(0,0,0,0.8);
    }
    &__title{
        margin-top: 10px;
        margin-bottom: 30px;
    }
    &__signin{
        width: 100%;
        margin: auto;
    }
    &__form{
        margin-top: 30px;
    }
    &__manageLinks{
        margin: 10px 0px 0px 0px;
    }
    &__forgotLink{
        text-align: left;
        font-size: 12px;
    }
    &__registerLink{
        text-align: left;
        font-size: 12px;
    }
    &__show{
        text-align: right;
        font-size: 0.7em;
    } 
    &__cartItemName{
        text-transform: none;
    }
    &__actionButton{ 
        color: #3f51b5;
        background-color: transparent;
        & svg{
            width: 2.5em;
            height: 2.5em;
        }
        &:hover, &:active{
            background-color:transparent;
            border: none;
            color: #3c3f57;
        }          
    }
    &__dangerButton{ 
        background-color:transparent;
        color: #d9534f;
        & svg{
            width: 2.5em;
            height: 2.5em;
        }
        &:hover{
            background-color:transparent;
            color: #b44946;
        } 
        &:active{
            background-color:transparent;
            color: #b44946;
        }      
    }
    &__currency{
        text-transform: none;
    } 
    &__info{
        font-size: 0.8em;
        font-style: italic ;
        padding-bottom: 10px;
    }
    &__modal{
        border: 10px solid rgba(255,255,255,0.2);
        border-radius: 5px;
    }
    &__grid{ 
        padding-bottom: 10px;
    }
    &__outline {
        font-weight: bold;
        color: #000;
        font-size: 1em;
        margin-bottom: 10px;
    }
    &__Innercontainer{
        padding-bottom: 20px;
    }
    &__rVieEventtitle{
        color: #006db3;
        font-size: 1.05em;
        padding-bottom: 0.5em;
    }
    &__rVieInfo{
        font-size: 0.9em;
        color: rgba(0,0,0,0.5);
        padding-bottom: 0.2em;
        font-style:italic;
    }
    &__rVieInfoLnk{
        margin: auto;
        text-align: left;
        font-size: 0.8em;
        padding: 0 0;
        display: block;
        margin-top: 5px;
        margin-bottom: 10px;

    }    
    &__rVieBtnInfo{
        text-align: left;
        font-size: 0.9em;
        font-weight: 500;
        color: rgba(0,0,0,0.9);
        padding-bottom: 0.2em;
    }  
    &__rVieModalBtnInfo{
        text-align: center;
        font-size: 0.8em;
        font-weight: 500;
        color: rgba(0,0,0,0.9);
        padding-bottom: 0.2em;
    }        
    &__rVieBtn{
        min-height: 40px;
        width: 180px;
        padding: 0.5rem 1rem;
        margin: 3px auto 3px auto;
        font-size: 0.7em;
        background-color: #3f51b5;
        border-color:  #3f51b5;
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: #3f51b5;
            opacity: 0.9;    
            color: #fff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }

    } 
    &__rVieSmallBtnDisabled{
        min-height: 40px;
        width: 100px;
        padding: 0.2rem 1rem;
        margin: 3px auto 3px auto;
        font-size: 0.7em;
        background-color: rgba(0,0,0,0.4);
        border-color:  rgba(0,0,0,0.4);
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    } 

    &__rVieSmallBtn{
        min-height: 40px;
        width: 100px;
        padding: 0.2rem 1rem;
        margin: 3px auto 3px auto;
        font-size: 0.7em;
        background-color: #3f51b5;
        border-color:  #3f51b5;
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: #3f51b5;
            opacity: 0.9;    
            color: #fff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }

    }     
    &__rVieDangerBtn{
        min-height: 40px;
        width: 180px;
        padding: 0.5rem 1rem;
        margin: 3px auto 3px auto;
        font-size: 0.7em;
        background-color: rgba(0,0,0,0.6);
        border-color: rgba(0,0,0,0.65);
        color: #fff;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: rgba(0,0,0,0.4);
            opacity: 0.9;    
            color: #fff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }

    }     
    
    &__rVieBtnOutline{
        min-height: 40px;
        width: 180px;
        padding: 0.5rem 1rem;
        margin: 3px auto 3px auto;
        font-size: 0.7em;
        background-color: #fff;
        border-color:  #3f51b5;
        border-width: 1px;
        color: #3f51b5;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: #fff;
            opacity: 0.9;    
            color: #3f51b5;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }

    } 
    &__rVieSmallBtnOutline{
        min-height: 40px;
        width: 100px;
        padding: 0.5rem 1rem;
        margin: 3px auto 3px auto;
        font-size: 0.7em;
        background-color: #fff;
        border-color:  #3f51b5;
        border-width: 1px;
        color: #3f51b5;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        &:hover, &:active{
            background-color: #fff;
            opacity: 0.9;    
            color: #3f51b5;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);            
        }

    }     
    &__rVieMultiBtn{
        min-height: 40px;
        width: 180px;
        padding: 0.5rem 1rem;
        margin: 3px auto 3px auto;
        font-size: 0.7em;
        background-color: #3f51b5;
    }
    &__rVieBtnInfoCenter{
        text-align: center;
    }
    &__rVieLnk{
        margin:auto;
        text-align: center;
    }
    &__rVieBtn:hover{
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    &p__rVieOptionBlock{
        text-align: center;
        margin: auto;
        display: block;
    }
    &__rVieModalFooterText{
        font-size: 0.7em;
        display: block;
    } 
    &__strongLegend{
        color:#000;
        font-weight: 800;
    } 
    &__strong{
        color:#000;
        font-weight: bold;
    } 
    &__rowCenter{
        justify-content: center;
    } 
    &__quantity{
        padding-top: 12px;
    }   
    &__quantityValue{
        text-indent: calc(10px);
    } 
    &__dateBox{
        text-align: center;
        border: 1px solid rgba(0,0,0,0.02);
        background-color:rgba(0, 0, 0, 0.05);
        padding-bottom: 15px;
        padding-top: 15px;
    }  
    &__dateBoxSelected{
        text-align: center;
        border: 1px solid #39C16C;
        background-color:rgba(0, 0, 0, 0.05);
        padding-bottom: 15px;
        padding-top: 15px;
        border-radius:10px;
    } 
    &__dateBoxInactive{
        text-align: center;
        border: 1px solid rgba(0,0,0,.01);
        background-color: rgba(0,0,0,.04);
        padding-bottom: 15px;
        padding-top: 15px;
        border-radius:10px;
        cursor:not-allowed;
        color: rgba(0,0,0,0.4);
    }     
    
    &__dateBoxDay{
        font-weight: 900;
        font-size: 1.2em;
    } 
    &__dateBoxMonth{
        font-weight: 900;
        font-size: 1.2em;
    }
    &__dateBoxYear{
        font-size: 0.8em;
    }    
    &__dateBoxTime{
        margin-top: 2px;
        font-size: 1em;
    }  
    &__dateCheckBox{
        text-align: center;
    } 
    &__dateRowBox{
        padding-left: 5px !important;
        padding-right: 5px !important;
    } 
    &__selectMore{
        color: #3f51b5;
        font-size: 0.8em;
    }                
}