

.p{
    &__rVieWrapper{
        max-width: 1280px;
        margin: auto;
    }  
}




